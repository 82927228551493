import unsignedFetch from '../util/unsignedFetch';

export default params => {
  let url = '';
  if (params.platform && params.platform === 'ELTCORE') {
    url = `${__API_BASE__}/open/products/structure?content-code=${params['content-code']}&depth=${params.depth}&platform=${params.platform}`;
  } else {
    url = params.isbn
      ? `${__API_BASE__}/open/products/structure?isbn=${params.isbn}&depth=${params.depth}`
      : `${__API_BASE__}/open/products/structure?content-code=${params['content-code']}&depth=${params.depth}`;
  }

  return unsignedFetch('getOpenProductStructure', url, 'GET');
};
