import PropTypes from 'prop-types';
import React from 'react';
import content from '../../utils/cmsContent';
import PopoutPanel from '../../components/PopoutPanel/PopoutPanel';
import SearchInitialiser from '../../components/SearchInitialiser/SearchInitialiser';
import UrlControlledTabs from '../../components/UrlControlledTabs/UrlControlledTabs';
import colors from '../../globals/colors';
import { featureIsEnabled } from '../../globals/envSettings';
import { isOrbMode } from '../../utils/platform';
import { orgRoles } from '../../globals/orgRoles';
import userRoles from '../../globals/userRoles';
import EditClassroom from '../ClassroomEdit/EditClassroom';
import Gradebook from '../Gradebook';
import AssignmentsTab from './tabs/AssignmentsTab';
import StudentsTab from './tabs/StudentsTab/StudentsTab';
import TeachersTab from './tabs/TeachersTab/TeachersTab';

function LoadedClassPageContent({
  orgId,
  orgRole,
  classId,
  classArchived,
  params,
  location,
  currentUserCanEditClass,
  isEditClassOpen,
  closeEditPanel,
  classStudentsDataRecency,
  classTeachersDataRecency,
  classAssignmentsDataRecency,
  canManageAssignments = true,
  showGBTabOnAssignments = false,
  openEditPanelOnClick,
  classStudents
}) {
  const CMS = content.classPage || {};
  const items = [
    {
      urlName: 'students',
      tabText: CMS.tabs_students_text,
      isContentLoading: classStudentsDataRecency.syncing,
      isContentLoaded: classStudentsDataRecency.synced,
      panelContent: (
        <SearchInitialiser
          searchSource="classStudents"
          initialFilters={{
            classId,
            orgId,
            roles: [userRoles.MANAGED_USER, userRoles.LEARNER],
            active: true,
            invited: true
          }}
        >
          <StudentsTab
            orgId={orgId}
            classroomId={params.classroomId}
            tabName={params.tabName}
            panelName={params.panelName}
            currentUserCanEditClass={currentUserCanEditClass}
            canManageAssignments={canManageAssignments}
            openEditPanelOnClick={openEditPanelOnClick}
            classArchived={classArchived}
          />
        </SearchInitialiser>
      )
    },
    {
      urlName: 'teachers',
      tabText: CMS.tabs_teachers_text,
      isContentLoading: classTeachersDataRecency.syncing,
      isContentLoaded: classTeachersDataRecency.synced,
      panelContent: (
        <SearchInitialiser
          searchSource="classTeachers"
          initialFilters={{
            classId,
            orgId,
            roles: ['TEACHER'],
            active: true,
            invited: true
          }}
        >
          <TeachersTab
            orgId={orgId}
            classroomId={params.classroomId}
            tabName={params.tabName}
            panelName={params.panelName}
            currentUserCanEditClass={currentUserCanEditClass}
            canManageAssignments={canManageAssignments}
          />
        </SearchInitialiser>
      )
    },
    ...(orgRole !== orgRoles.PRIMARY_SCHOOL
      ? [
          {
            urlName: 'assignments',
            tabText: CMS.tabs_assignments_text,
            isContentLoading: classAssignmentsDataRecency.syncing,
            isContentLoaded: classAssignmentsDataRecency.synced,
            panelContent: (
              <SearchInitialiser
                searchSource="classAssignments"
                initialFilters={{
                  classId,
                  orgId,
                  searchAssignments: true,
                  active: true
                }}
              >
                <AssignmentsTab
                  orgId={orgId}
                  classroomId={params.classroomId}
                  tabName={params.tabName}
                  panelName={params.panelName}
                  currentUserCanEditClass={currentUserCanEditClass}
                  canManageAssignments={canManageAssignments}
                />
              </SearchInitialiser>
            )
          }
        ]
      : [])
  ];

  const gradebookItem = {
    urlName: 'gradebook',
    tabText: CMS.tabs_classProgress_text,
    panelContent: <Gradebook params={params} />
  };
  const showGradebookTab = featureIsEnabled('olb-gradebook-uis') && !isOrbMode() && showGBTabOnAssignments;

  return (
    <div>
      <UrlControlledTabs
        backgroundColor={classArchived ? colors.ARCHIVED : colors.CLASSROOM}
        tabName={params.tabName}
        pathname={location.pathname}
        items={showGradebookTab ? items.concat(gradebookItem) : items}
      />

      {currentUserCanEditClass && classStudents && !classStudents.loading && (
        <PopoutPanel id="editClassPopout" ariaLabel="Edit Class" isOpen={isEditClassOpen}>
          <EditClassroom
            orgId={orgId}
            classroomId={classId}
            closePopoutAction={closeEditPanel}
            currentTab={params.tabName}
          />
        </PopoutPanel>
      )}
    </div>
  );
}

LoadedClassPageContent.propTypes = {
  orgId: PropTypes.string.isRequired,
  orgRole: PropTypes.string.isRequired,
  classId: PropTypes.string.isRequired,
  classArchived: PropTypes.bool,
  params: PropTypes.any,
  location: PropTypes.any,
  currentUserCanEditClass: PropTypes.bool.isRequired,
  closeEditPanel: PropTypes.func.isRequired,
  isEditClassOpen: PropTypes.bool.isRequired,
  classStudentsDataRecency: PropTypes.object.isRequired,
  classTeachersDataRecency: PropTypes.object.isRequired,
  classAssignmentsDataRecency: PropTypes.object.isRequired,
  canManageAssignments: PropTypes.bool,
  showGBTabOnAssignments: PropTypes.bool,
  openEditPanelOnClick: PropTypes.func.isRequired,
  classStudents: PropTypes.object
};

export default LoadedClassPageContent;
